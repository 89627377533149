<template>
  <content-not-view v-if="!subPermission.index" />
  <Loading v-else-if="isLoading" />
  <div v-else class="users-list">
    <b-card>
      <div class="custom-search d-flex justify-content-between mb-1 options">
        <!-- Start Search Input -->
        <b-form-group>
          <div class="d-flex align-items-end">
            <div class="mr-2">
              <label class="mr-1">{{ $t("g.searchLabel") }}</label>
              <b-form-input
                v-model="searchTerm"
                :placeholder="$t('g.searchHere')"
                type="text"
                class="d-inline-block"
              />
            </div>
            <b-form-select
              v-model="filterQuery"
              :options="filterDropdownItems"
            />
          </div>
        </b-form-group>
        <div class="d-flex align-items-center" v-if="subPermission.store">
          <b-button :to="{ name: 'AddUser' }" variant="primary" class="w-100">{{
            $t("g.users/userList/add_user")
          }}</b-button>
        </div>
        <!-- End Search Input -->
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="vgt-table striped condensed"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'name'" class="text-nowrap">
            {{ $t("g.name") }}
          </span>
          <span v-else-if="props.column.label === 'email'" class="text-nowrap">
            {{ $t("g.email") }}
          </span>
          <span
            v-else-if="props.column.label === 'phone_number'"
            class="text-nowrap"
          >
            {{ $t("g.phone_number") }}
          </span>
          <span v-else-if="props.column.label === 'type'" class="text-nowrap">
            {{ $t("g.users/userList/type") }}
          </span>
          <span
            v-else-if="props.column.label === 'user_name'"
            class="text-nowrap"
          >
            {{ $t("g.users/userList/user_name") }}
          </span>
          <span
            v-else-if="props.column.label === 'birthdate'"
            class="text-nowrap"
          >
            {{ $t("g.users/userList/birthdate") }}
          </span>
          <span
            v-else-if="props.column.label === 'created_at'"
            class="text-nowrap"
          >
            {{ $t("g.users/userList/created_at") }}
          </span>
          <span
            v-else-if="props.column.label === 'is_blocked'"
            class="text-nowrap"
          >
            {{ $t("g.users/userList/is_blocked") }}
          </span>
          <span v-else-if="props.column.label === 'action'" class="text-nowrap">
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'created_at'" class="text-nowrap">
            {{ props.row ? formateDate(props.row.created_at) : "created date" }}
          </span>
          <span v-else-if="props.column.field === 'type'" class="text-nowrap">
            {{
              props.row && props.row.type === "guardian"
                ? $t("parent")
                : $t(props.row.type)
            }}
          </span>
          <span
            v-else-if="props.column.field === 'is_blocked'"
            class="text-nowrap"
          >
            {{ $t(`users.${props.row.is_blocked}`) }}
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  :to="`/users/edit-user/${props.row.id}`"
                  v-if="subPermission.update"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="deleteUser(props.row.id)"
                  v-if="subPermission.destroy"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    props.row.type !== 'admin' && subPermission.GetAccessToken
                  "
                  @click="getAccess(props.row.id, props.row.type)"
                >
                  <img
                    :src="getAccessIcon"
                    width="14"
                    height="14"
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>{{ $t("g.users/userList/get_access") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <template slot="emptystate">
          <span class="text-center" style="width: 100%; display: inline-block;">
            {{ $t("g.no_data_for_table") }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              />
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <overlay-component :isLoading="overlayLoading" />
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BDropdownItem,
  BDropdown,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import dateTimeInFormat from "@/composables/format-date/format-date.js";
import OverlayComponent from "@/components/shared/OverlayComponent";
import Loading from "@/components/loading/loading.vue";
import contentNotView from "@/components/contentNotView/contentNotView.vue";

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    BDropdownItem,
    BDropdown,
    OverlayComponent,
    Loading,
    contentNotView,
  },
  data() {
    return {
      dir: false,
      isLoading: true,
      isError: false,
      overlayLoading: false,
      pageLength: 20,
      searchTerm: "",
      tableBody: [],
      archive: [],
      filterQuery: "all",
      formateDate: dateTimeInFormat,
      getAccessIcon: require("@/assets/images/icons/getAccess.svg"),
      tableHeader: [
        {
          label: "name",
          field: "name",
        },
        {
          label: "email",
          field: "email",
        },
        {
          label: "type",
          field: "type",
        },
        {
          label: "phone_number",
          field: "phone_number",
        },
        {
          label: "is_blocked",
          field: "is_blocked",
        },
        {
          label: "action",
          field: "action",
        },
      ],
      filterDropdownItems: [
        { value: "all", text: this.$t("All Users") },
        { value: "admin", text: this.$t("admin") },
        { value: "teacher", text: this.$t("teacher") },
        { value: "student", text: this.$t("student") },
        { value: "guardian", text: this.$t("parent") },
      ],
    };
  },

  created() {
    this.getUsersList();
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "users");
      return this.$store.state.userData.sub_Permission;
    },
  },
  methods: {
    async getUsersList() {
      try {
        const response = (await this.$http.get("admin/users")).data.data;
        this.tableBody = [response].flat().map((item) => ({
          id: item.id,
          name: `${item.first_name} ${item.last_name}`,
          email: item.email,
          phone_number: item?.phone[0]?.phone,
          type: item.type,
          user_name: item.user_name,
          birthdate: item.birthdate,
          created_at: item.created_at,
          is_blocked: item.is_blocked,
        }));
        this.archive = this.tableBody;
      } catch (error) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async deleteUser(userId) {
      try {
        this.$helpers.sweetAlertConfirm(
          this.$t("g.areYouSure?"),
          this.$t("g.youWontBeAbleToRevertThis"),
          "warning",
          this.$t("g.yesDeleteIt"),
          this.$t("g.cancel"),
          () => this.$http.delete(`admin/users/${userId}`)
        );
        this.overlayLoading = true;
        this.getUsersList();
      } catch (error) {
        this.isError = true;
      } finally {
        this.overlayLoading = false;
      }
    },
    async getAccess(userId, userType) {
      try {
        this.overlayLoading = true;
        const res = await this.$http.get(
          `/admin/users/get-access-token/${userId}`
        );
        userType === "guardian"
          ? (window.location.href = `https://parent.futureskill.geexar.dev/#/login?guestToken=${res.data.token}`)
          : (window.location.href = `https://${userType}.futureskill.geexar.dev/#/login?guestToken=${res.data.token}`);
      } catch (error) {
        this.isError = true;
      } finally {
        this.overlayLoading = false;
      }
    },
    filterFn: function (data, filterString) {
      var x = parseInt(filterString);
      return data >= x - 5 && data <= x + 5;
    },
  },
  watch: {
    filterQuery(newValue, oldValue) {
      const newData = [];
      if (newValue === "all") {
        this.tableBody = this.archive;
      } else {
        this.archive.map((user) => {
          if (user.type === newValue) {
            newData.push(user);
          }
        });
        this.tableBody = newData;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./index";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
